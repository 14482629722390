// import CryptoJS from '../node_modules/crypto-js/crypto-js.js'
import CryptoJS from "crypto-js";
import {JSEncrypt} from "jsencrypt";

const DEFAULT_KEY = "12345678901234567890123456789012"; // 默认32位ASCII密钥
const DEFAULT_IV = "1234567890123456"; // 默认16位ASCII向量

export function GenerateKey() {
    const uuidStr =
        (Math.random() * 10000000).toString(16).substr(0, 4) +
        new Date().getTime() +
        Math.random().toString().substr(2, 5);
    return uuidStr;
}

/**
 * AES加密
 */
export function Encrypt(word, keyStr, ivStr) {
    let key;
    let iv;

    if (keyStr) {
        key = CryptoJS.enc.Utf8.parse(keyStr);
        iv = CryptoJS.enc.Utf8.parse(ivStr);
    }

    let encrypted = CryptoJS.AES.encrypt(word, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    let cipherText = encrypted.ciphertext.toString();

    return cipherText;
}

export function RSAencrypt(content, publicKey) {
    let jse = new JSEncrypt();
    jse.setPublicKey(publicKey);

    const result = jse.encrypt(content);
    return result;
}

export function GenerateRandomString16() {
    let x = "0123456789qwertyuioplkjhgfdsazxcvbnm";
    let tmp = "";
    for (let i = 0; i < 16; i++) {
        tmp += x.charAt(Math.ceil(Math.random() * 100000000) % x.length);
    }
    return tmp;
}

export function Decrypt2(word, keyStr) {
    let key = CryptoJS.enc.Utf8.parse(keyStr);
    let decrypt = CryptoJS.AES.decrypt(word, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    });
    let ss = CryptoJS.enc.Utf8.stringify(decrypt).toString();
    return ss;
}

export function Decrypt(cipherText, keyStr, ivStr) {
    try{
    let cipherTextHexStr = CryptoJS.enc.Hex.parse(cipherText);
    let cipherTextBase64Str = CryptoJS.enc.Base64.stringify(cipherTextHexStr);
    let key = CryptoJS.enc.Utf8.parse(keyStr);
    let iv = CryptoJS.enc.Utf8.parse(ivStr);
    let decrypt = CryptoJS.AES.decrypt(cipherTextBase64Str, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });
    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr;
        }catch (e){
        console.error('Decryption error:',e);
        return '';
    }
}

export const generateDynamicInput = (userId, deviceId) => {
    return `input_${userId}_${Date.now()}_${Math.random().toString(36).substring(2)}`;
};

export const generateKeyFromHash = input => {
    return CryptoJS.SHA256(input).toString(CryptoJS.enc.Hex).slice(0, 32); // 截取32字节
};

export const generateIvStr = () => {
    return Array.from({ length: 16 }, () =>
        ('0' + Math.floor(Math.random() * 256).toString(16)).slice(-2)
    ).join('');
};

export const encrypt3 = (
    plaintext,
    keyStr = DEFAULT_KEY,
    ivStr = DEFAULT_IV,
) => {
    const key = CryptoJS.enc.Utf8.parse(keyStr);
    const iv = CryptoJS.enc.Utf8.parse(ivStr);

    console.log('use cbc to encrypt content')
    const encrypted = CryptoJS.AES.encrypt(plaintext, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });
    const cipherHex = encrypted.ciphertext.toString(CryptoJS.enc.Hex);
    return cipherHex;
};

export const decrypt3 = (
    cipherHex,
    keyStr = DEFAULT_KEY,
    ivStr = DEFAULT_IV,
) => {
    const key = CryptoJS.enc.Utf8.parse(keyStr);
    const iv = CryptoJS.enc.Utf8.parse(ivStr);
    const decipherBase64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(cipherHex));
    const dec = CryptoJS.AES.decrypt(decipherBase64, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });
    return dec.toString(CryptoJS.enc.Utf8);
};
