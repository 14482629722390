import Header1 from "../common/Header1";
import {
    Card,
    CardContent,
    CircularProgress,
    Grid,
    IconButton,
    TextField, Toolbar
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MyHistoryRow from "../myHistory/MyHistoryRow";
import {useTheme} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {apiLoadHistoryHome} from "../../api/Api";
import {useSnackbar} from "../../component/SnackbarProvider";

const MemoryHome = () => {
    const theme = useTheme()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [searchKey, setSearchKey] = useState('')
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [noteList, setNoteList] = useState([])
    const [loading, setLoading] = useState(true)
    const {showMessage} = useSnackbar()

    useEffect(() => {
        loadAllData()
    }, [pageIndex, pageSize])

    const loadAllData = () => {
        let params = {
            pageIndex,
            pageSize,
            searchKey
        };
        apiLoadHistoryHome(params)
            .then((res: any) => {
                if (res.code === 0) {
                    // dispatch(saveHistoryNoteList(res.data.noteList));
                    // setLoading(false);
                    setNoteList(res.data.noteList)
                    setLoading(false)
                } else {
                    if (res.code === 10003) {
                        showMessage(t('syserr.' + 10004), 'error')
                        setTimeout(() => {
                            navigate('/LoginPage')
                        }, 2000)
                    } else {
                        showMessage(t('syserr.' + res.code), 'error')
                    }
                }
            })
            .catch(() => {
                showMessage(t('syserr.10001'), 'error')
            });
    };

    return (
        <div>
            <Header1/>
            <Toolbar/>
            <div style={{display: 'flex', justifyContent: 'center', padding: 10}}>
                <div style={{maxWidth: 1080, width: '100%'}}>

                    <Card style={{
                        marginTop: 10, background: theme.palette.background.default
                    }}>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <TextField
                                            style={{width: '100%', padding: 0}}
                                            placeholder={t('history.searchHolder')}
                                            onChange={e => {
                                                setSearchKey(e.target.value)
                                            }}
                                        />
                                        <IconButton onClick={() => {
                                            if (pageIndex !== 1) {
                                                setPageIndex(1)
                                            } else {
                                                loadAllData()
                                            }
                                        }}>
                                            <SearchIcon style={{color: theme.palette.primary.main}}/>
                                        </IconButton>
                                    </div>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    {
                        loading ?
                            <div style={{textAlign: 'center', marginTop: 200}}>
                                <CircularProgress/>
                            </div>
                            :
                            <div>
                                {noteList.length > 0 ?
                                    <div>
                                        {noteList.map((item, index) => (
                                            <MyHistoryRow data={item} key={index}/>
                                        ))
                                        }
                                    </div>
                                    :
                                    <div>no data</div>
                                }
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}
export default MemoryHome
