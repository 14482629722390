import React, {createContext, ReactNode, useContext, useState} from "react";
import {Alert, AlertColor, Snackbar} from "@mui/material";

interface SnackbarContextType {
    showMessage: (message: string, type?: AlertColor) => void
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined)

interface SnackbarProviderProps {
    children: ReactNode
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({children}) => {
    const [snackbar, setSnackbar] = useState<{ message: string; type: AlertColor; open: boolean }>({
        message: '',
        type: 'error',
        open: false
    })

    const showMessage = (message: string, type: AlertColor = 'error') => {
        setSnackbar({message, type, open: true})
    }

    const handleClose = () => {
        setSnackbar({...snackbar, open: false})
    }

    return (
        <SnackbarContext.Provider value={{showMessage}}>
            {children}
            <Snackbar open={snackbar.open} autoHideDuration={3000} onClose={handleClose}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Alert onClose={handleClose} severity={snackbar.type} variant='filled'>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    )
}
export const useSnackbar = (): SnackbarContextType => {
    const context = useContext(SnackbarContext)
    if (!context) {
        throw new Error('useSnackbar must be used within a SnackbarProvider')
    }
    return context
}
